import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: "/caine2/yes0.png",
    original: "/caine2/yes0.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes1.png",
    original: "/caine2/yes1.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes2.png",
    original: "/caine2/yes2.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes3.png",
    original: "/caine2/yes3.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes4.png",
    original: "/caine2/yes4.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes5.png",
    original: "/caine2/yes5.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes6.png",
    original: "/caine2/yes6.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes7.png",
    original: "/caine2/yes7.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes8.png",
    original: "/caine2/yes8.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes9.png",
    original: "/caine2/yes9.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes10.png",
    original: "/caine2/yes10.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes11.png",
    original: "/caine2/yes11.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes12.png",
    original: "/caine2/yes12.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/caine2/yes13.png",
    original: "/caine2/yes13.png",
    width: 320,
    height: 320,
    caption: "AIxPhilosophy",
  },
  {
    src: "/luckyluke/yes-0.png",
    original: "/luckyluke/yes-0.png",
    width: 320,
    height: 320,
    caption: "OldWest vs New Future",
  },
  {
    src: "/luckyluke/yes-1.png",
    original: "/luckyluke/yes-1.png",
    width: 320,
    height: 320,
    caption: "OldWest vs New Future",
  },
  {
    src: "/luckyluke/yes-2.png",
    original: "/luckyluke/yes-2.png",
    width: 320,
    height: 320,
    caption: "OldWest vs New Future",
  },
  {
    src: "/luckyluke/yes-3.png",
    original: "/luckyluke/yes-3.png",
    width: 320,
    height: 320,
    caption: "OldWest vs New Future",
  },
  {
    src: "/luckyluke/yes-4.png",
    original: "/luckyluke/yes-4.png",
    width: 320,
    height: 320,
    caption: "OldWest vs New Future",
  },
  {
    src: "/luckyluke/yes-5.png",
    original: "/luckyluke/yes-5.png",
    width: 320,
    height: 320,
    caption: "OldWest vs New Future",
  },
  {
    src: "/luckyluke/yes-6.png",
    original: "/luckyluke/yes-6.png",
    width: 320,
    height: 320,
    caption: "OldWest vs New Future",
  },
  {
    src: "/luckyluke/yes-7.png",
    original: "/luckyluke/yes-7.png",
    width: 320,
    height: 320,
    caption: "OldWest vs New Future",
  },
  {
    src: "/luckyluke/yes-8.png",
    original: "/luckyluke/yes-8.png",
    width: 320,
    height: 320,
    caption: "OldWest vs New Future",
  },
  {
    src: "/luckyluke/yes-9.png",
    original: "/luckyluke/yes-9.png",
    width: 320,
    height: 320,
    caption: "OldWest vs New Future",
  },
  {
    src: "/littleroad/yes0.png",
    original: "/littleroad/yes0.png",
    width: 320,
    height: 320,
    caption: "Little Red Riding Hood",
  },
  {
    src: "/littleroad/yes1.png",
    original: "/littleroad/yes1.png",
    width: 320,
    height: 320,
    caption: "Little Red Riding Hood",
  },
  {
    src: "/littleroad/yes2.png",
    original: "/littleroad/yes2.png",
    width: 320,
    height: 320,
    caption: "Little Red Riding Hood",
  },
  {
    src: "/littleroad/yes3.png",
    original: "/littleroad/yes3.png",
    width: 320,
    height: 320,
    caption: "Little Red Riding Hood",
  },
  {
    src: "/littleroad/yes4.png",
    original: "/littleroad/yes4.png",
    width: 320,
    height: 320,
    caption: "Little Red Riding Hood",
  },
  {
    src: "/littleroad/yes5.png",
    original: "/littleroad/yes5.png",
    width: 320,
    height: 320,
    caption: "Little Red Riding Hood",
  },
  {
    src: "/littleroad/yes6.png",
    original: "/littleroad/yes6.png",
    width: 320,
    height: 320,
    caption: "Little Red Riding Hood",
  },
  {
    src: "/littleroad/yes7.png",
    original: "/littleroad/yes7.png",
    width: 320,
    height: 320,
    caption: "Little Red Riding Hood",
  },
  {
    src: "/littleroad/yes8.png",
    original: "/littleroad/yes8.png",
    width: 320,
    height: 320,
    caption: "Little Red Riding Hood",
  },

  {
    src: "/assasinscat/yes0.webp",
    original: "/assasinscat/yes0.webp",
    width: 320,
    height: 320,
    caption: "Guardians of the Crescent Moon",
  },
  {
    src: "/assasinscat/yes1.png",
    original: "/assasinscat/yes1.png",
    width: 320,
    height: 320,
    caption: "Guardians of the Crescent Moon",
  },
  {
    src: "/assasinscat/yes2.png",
    original: "/assasinscat/yes2.png",
    width: 320,
    height: 320,
    caption: "Guardians of the Crescent Moon",
  },
  {
    src: "/assasinscat/yes3.png",
    original: "/assasinscat/yes3.png",
    width: 320,
    height: 320,
    caption: "Guardians of the Crescent Moon",
  },
  {
    src: "/assasinscat/yes4.png",
    original: "/assasinscat/yes4.png",
    width: 320,
    height: 320,
    caption: "Guardians of the Crescent Moon",
  },
  {
    src: "/assasinscat/yes5.png",
    original: "/assasinscat/yes5.png",
    width: 320,
    height: 320,
    caption: "Guardians of the Crescent Moon",
  },
  {
    src: "/assasinscat/yes6.png",
    original: "/assasinscat/yes6.png",
    width: 320,
    height: 320,
    caption: "Guardians of the Crescent Moon",
  },
  {
    src: "/assasinscat/yes7.png",
    original: "/assasinscat/yes7.png",
    width: 320,
    height: 320,
    caption: "Guardians of the Crescent Moon",
  },
  {
    src: "/assasinscat/yes8.png",
    original: "/assasinscat/yes8.png",
    width: 320,
    height: 320,
    caption: "Guardians of the Crescent Moon",
  },
  {
    src: "/kara/yes0.jpeg",
    original: "/kara/yes0.jpeg",
    width: 320,
    height: 320,
    caption: "Kara & Tugrul",
  },
  {
    src: "/kara/yes1.jpeg",
    original: "/kara/yes1.jpeg",
    width: 320,
    height: 320,
    caption: "Kara & Tugrul",
  },
  {
    src: "/kara/yes2.jpeg",
    original: "/kara/yes2.jpeg",
    width: 320,
    height: 320,
    caption: "Kara & Tugrul",
  },
  {
    src: "/kara/yes3.jpeg",
    original: "/kara/yes3.jpeg",
    width: 320,
    height: 320,
    caption: "Kara & Tugrul",
  },
  {
    src: "/kara/yes4.jpeg",
    original: "/kara/yes4.jpeg",
    width: 320,
    height: 320,
    caption: "Kara & Tugrul",
  },
  {
    src: "/kara/yes5.jpeg",
    original: "/kara/yes5.jpeg",
    width: 320,
    height: 320,
    caption: "Kara & Tugrul",
  },
  {
    src: "/kara/yes6.jpeg",
    original: "/kara/yes6.jpeg",
    width: 320,
    height: 320,
    caption: "Kara & Tugrul",
  },
  {
    src: "/kara/yes7.jpeg",
    original: "/kara/yes7.jpeg",
    width: 320,
    height: 320,
    caption: "Kara & Tugrul",
  },
];
